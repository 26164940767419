import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import { getPostObj } from "../utils/utils"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import HeadingParagraphButton from "../components/CTA/HeadingParagraphButton"
import { Link, graphql } from "gatsby"
import { instructions } from "../cms/collections/instructions"

import SEO from "../components/seo"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

const PatientInstructionsPage = ({ pageContext, location, data }) => {
  var post
  var language
  let postObj = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  post = postObj.post
  language = postObj.language

  var instructionsData

  switch (language) {
    case "en":
      instructionsData = data.allInstructionsJson.nodes
      break
    case "es":
      instructionsData = data.allSpanishInstructionsJson.nodes
      break
    default:
      instructionsData = data.allInstructionsJson.nodes
  }

  sortArchive(instructionsData)

  var instructionsPre = instructionsData.map(instruction => {
    if (instruction.preOrPost === "pre") {
      return (
        <InstructionItem
          language={language}
          preOrPost={instruction.preOrPost}
          url={instruction.title}
          name={instruction.name}
          youtube={instruction.youtube}
          instructionBlockHeading={instruction.instructionBlockHeading}
        />
      )
    }
  })

  var instructionsPostsWithVideo = instructionsData.map(instruction => {
    if (instruction.preOrPost === "post" && instruction.youtube) {
      return (
        <InstructionItem
          language={language}
          preOrPost={instruction.preOrPost}
          url={instruction.title}
          name={instruction.name}
          youtube={instruction.youtube}
          instructionBlockHeading={instruction.instructionBlockHeading}
        />
      )
    }
  })

  var instructionsPostsNoVideo = instructionsData.map(instruction => {
    if (instruction.preOrPost === "post" && !instruction.youtube) {
      return (
        <InstructionItem
          language={language}
          preOrPost={instruction.preOrPost}
          url={instruction.title}
          name={instruction.name}
          youtube={instruction.youtube}
          instructionBlockHeading={instruction.instructionBlockHeading}
        />
      )
    }
  })

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <div className="joshua-tree-content instructions-query">
          <div className="columns top-section text-section">
            <div className="column">
              <div className="columns is-mobile">
                <div className="column is-4"></div>
                <div className="column">
                  <div>
                    <h1 style={{ marginTop: 0 }}>{post.heading}</h1>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-4"></div>
                <div
                  className="column is-16"
                  dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.topBlurb)
                  )}
                ></div>
                <div className="column is-4"></div>
              </div>
            </div>
          </div>
          <section className="body-sections section instructions-body">
            <div className="columns operative-text-section">
              <div className="column">
                <div className="columns">
                  <div className="column is-4"></div>
                  <div className="column pre-post-text">
                    <h4>{post.preOpSmallHeading}</h4>
                    <h3>{post.preOpBigHeading}</h3>
                    <div
                      dangerouslySetInnerHTML={createHtml(
                        converter.makeHtml(post.preOpBlurb)
                      )}
                    ></div>
                  </div>
                  <div className="column is-6"></div>
                </div>
              </div>
            </div>
            <div className="pre-op-instructions-wrapper">{instructionsPre}</div>
            <div className="columns operative-text-section">
              <div className="column">
                <div className="columns">
                  <div className="column is-4"></div>
                  <div className="column pre-post-text">
                    <h4>{post.postOpSmallHeading}</h4>
                    <h3>{post.postOpBigHeading}</h3>
                    <div
                      dangerouslySetInnerHTML={createHtml(
                        converter.makeHtml(post.postOpBlurb)
                      )}
                    ></div>
                  </div>
                  <div className="column is-6"></div>
                </div>
              </div>
            </div>
            <div className="post-op-instructions-wrapper">
              {instructionsPostsWithVideo}
              {instructionsPostsNoVideo}
            </div>
            <div>
              <HeadingParagraphButton
                sideColumnIs={4}
                heading={post.getStarted.heading}
                paragraph={post.getStarted.blurb}
                buttonText={post.getStarted.buttonText}
                buttonUrl={post.getStarted.href}
              />
            </div>
          </section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

function sortArchive(archiveArray) {
  archiveArray.sort((a, b) => (a.order > b.order ? 1 : -1))
}

function InstructionItem(props) {
  var youtube = props.youtube

  let subheading

  if (props.language === "es") {
    if (props.preOrPost === "pre") {
      subheading = "Preoperatorio"
    } else {
      subheading = "Postoperatorio"
    }
  } else {
    if (props.preOrPost === "pre") {
      subheading = "Pre-Operative"
    } else {
      subheading = "Post-Operative"
    }
  }

  return (
    <div className="columns instructions-block-wrapper">
      <div className="column is-3"></div>
      <div className="column instructions-block">
        <div className="left-is-1 right-is-1">
          <div className="columns is-vcentered">
            <div className="column">
              <h4 className="operative-subheading">{subheading}</h4>
              <h5 className="operative-heading">
                {props.instructionBlockHeading}
              </h5>
            </div>
            <div className="column">
              <div className="button-group-wrapper is-centered-mobile">
                <div className="button-group">
                  {youtube && (
                    <TheaterVideo
                      videoUrl={`http://youtube.com/watch?v=${youtube}`}
                      playing
                      onlyButton
                      language={props.language}
                      buttonClass="contained"
                    />
                  )}

                  <Button
                    contained
                    buttonText={
                      props.language === "es" ? "Aprende Más" : "Learn More"
                    }
                    href={"/" + props.url}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-3"></div>
    </div>
  )
}

export const pageQuery = graphql`
  query instructionsEs($title: String!) {
    allInstructionsJson {
      nodes {
        title
        name
        preOrPost
        youtube
        instructionBlockHeading
        order
      }
    }
    allSpanishInstructionsJson {
      nodes {
        title
        name
        preOrPost
        youtube
        instructionBlockHeading
        order
      }
    }
    allInstructionsJson {
      nodes {
        title
        name
        preOrPost
        youtube
        instructionBlockHeading
        order
      }
    }
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        schemaPage
        heading
        topBlurb
        preOpBlurb
        postOpBlurb
        preOpSmallHeading
        preOpBigHeading
        postOpBigHeading
        postOpSmallHeading
        getStarted {
          blurb
          buttonText
          heading
          href
        }
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        schemaPage
        heading
        topBlurb
        preOpBlurb
        postOpBlurb
        preOpSmallHeading
        preOpBigHeading
        postOpBigHeading
        postOpSmallHeading
        getStarted {
          blurb
          buttonText
          heading
          href
        }
      }
    }
  }
`

export default PatientInstructionsPage
